.button-container {
	--button-width: 225px;
	--button-height: 2em;
	background: var(--GRAY);
	height: var(--button-height);
	width: var(--button-width);
	vertical-align: center;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10em;
	font-size: 1.5em;
	color: var(--WHITE);
	text-decoration: none;
}

.button-container a {
	color: var(--WHITE);
	text-decoration: none;
}