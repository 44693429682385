

.text-input-container{
	--text-input-height: 2.5em;
	--text-input-width: 444px;
	height: var(--text-input-height, 75px);
	width: var(--text-input-width, 50%);
	position: relative;
}
.text-input-container .label{
	position: absolute;
	top: 50%;
	left: 25px;
	transform: translateY(-50%);
	transition: all 250ms ease-in;
	font-size: 1.2em;
	color: var(--GRAY);
}

.text-input-container .label.focused{
	top: 20%;
	font-size: .8em;
}


.text-input-container .input{
	height: 100%;
	width: 100%;
	border-radius: 10em;
	text-indent: 2em;
	font-size: 1em;
	border: solid var(--GRAY) 1px;
}