@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  font-size: 12pt;
  --RED: #FF0000;
  --MAGENTA: #FF00FF;
  --ORANGE: #FEA500;
  --YELLOW: #FFFF00;
  --GREEN: #00B050;
  --LIME: #7DEE20;
  --BLUE: #0070C0;
  --CYAN: #00B0F0;
  --GRAY: #84898C;
  --LIGHT_GRAY: #92989C;
  --DARK: #272729;
  --WHITE: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Source Code Pro', monospace;
}