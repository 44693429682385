.login {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
}

.login .container {
	padding: 2em;
	width: 545px;
	max-width: 100%;
	height: 500px;
	max-height: 100vh;
	border: solid 1px var(--ORANGE);
	border-radius: 2em;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.login .container>* {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.login .container .inputs-container >* {
	margin: calc(var(--text-input-height, 75px)*0.6);
	display: block;
}