.expiracion {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
}

.expiracion .container {
	padding: 2em;
	min-width: 545px;
	max-width: 100%;
	min-height: 500px;
	max-height: 100vh;
	border: solid 1px var(--ORANGE);
	border-radius: 2em;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.expiracion header {
	display: flex;
	align-items: center;
}

.expiracion .container .title {
	text-align: center;
	font-size: 1.2em;

}

.expiracion .container>* {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.expiracion .container .inputs-container {
	display: flex;
	flex-direction: row;
	gap: 5px;
	width: 100%;
}

.expiracion .container .inputs-container>* {
	margin: calc(var(--text-input-height, 75px)*0.6) 0;
	--text-input-width: 100%;
}

.expiracion .container p.expiracion-container {
	color: var(--ORANGE);
	font-size: 2em;
}

.expiracion .container .inputs-container .number-input {
	--text-input-width: 50%;
	margin: 0;
}

.expiracion>.container>.links {
	margin: 20px;
}

.expiracion>.container>.links * {
	color: var(--BLUE) !important;
	text-align: center;
}

.expiracion>.container>.links:hover {
	cursor: pointer;
}